<template>
  <div class="box my_warp">
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      style="width: 400px"
      label-position="top"
      :rules="rules"
    >
      <h1 style="text-align: center">实名认证</h1>
      <el-form-item label="真实姓名" prop="realname">
        <el-input v-model="form.realname"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="id_card_no">
        <el-input v-model="form.id_card_no"></el-input>
      </el-form-item>
      <el-form-item label="身份证正面" prop="id_card_front">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          action
          :http-request="Upload"
          :on-success="handleSuccess"
          :on-error="handfileErr"
          :before-upload="beforeAvatarUpload"
          v-model="form.id_card_front"
        >
          <img
            v-if="form.id_card_front"
            :src="form.id_card_front"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="身份证反面" prop="id_card_back">
        <el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :http-request="Upload"
          :on-success="handleAvatarSuccess"
          :on-error="handfileErrBack"
          :before-upload="beforeAvatarUpload"
          v-model="form.id_card_back"
        >
          <img
            v-if="form.id_card_back"
            :src="form.id_card_back"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-button
        style="width: 400px; height: 50px"
        type="success"
        @click="submitForm"
        >提交</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { client, getFileNameUUID, dateFormat } from "@/util/update";
let checkAge = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("身份证号码不能为空"));
  }
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  let flag = reg.test(value);
  if (flag) {
    callback();
  } else {
    callback(new Error("请输入正确的身份证号码"));
  }
};
let checkName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("姓名不能为空"));
  }
  let reg = /^[\u4e00-\u9fa5 ]{2,20}$/;
  let flag = reg.test(value);
  if (flag) {
    callback();
  } else {
    callback(new Error("请输入正确的姓名"));
  }
};
export default {
  data() {
    return {
      fileList: [], //文件列
      showProgress: false, //进度条的显示
      dataObj: {}, //存签名信息
      progress: 0, //进度条数据
      form: {
        realname: "",
        id_card_no: "",
        id_card_front: "",
        id_card_back: "",
      },
      rules: {
        realname: [
          { required: true, message: "请输入姓名", trigger: "change" },
          {
            validator: checkName,
            trigger: "blur",
          },
        ],
        id_card_no: [
          { required: true, message: "请输入身份证号码", trigger: "change" },
          { validator: checkAge, trigger: "blur" },
        ],
        id_card_front: [
          {
            required: true,
            message: "请选择身份证正面照片",
            trigger: "change",
          },
        ],
        id_card_back: [
          {
            required: true,
            message: "请选择身份证反面照片",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getOssData();
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 30;

      if (!isJPG) {
        this.$message.error("请选择正确的图片类型");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过30MB!");
      }
      return isJPG && isLt2M;
    },
    handleSuccess(res, file, fileList) {
      this.form.id_card_front = res.url;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.form.id_card_back = res.url;
    },
    async Upload(option) {
      const file = option.file;
      const that = this;
      const date = dateFormat(new Date(), "yyyyMMdd"); // 当前时间
      const dateTime = dateFormat(new Date(), "yyyyMMddhhmmss"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const extensionName = file.name.substr(file.name.indexOf(".")); // 文件扩展名
      const fileName =
        "image/" + date + "/" + dateTime + "_" + randomStr + extensionName;
      // 执行上传
      let dataObj = {
        accessKeyId: that.dataObj.AccessKeyId,
        secure: true,
        accessKeySecret: that.dataObj.AccessKeySecret,
        bucket: that.dataObj.bucket,
        region: that.dataObj.Region,
        stsToken: that.dataObj.SecurityToken,
      };
      try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        const result = await client(dataObj).put(fileName, file); // Message.success("上传成功");

        return result;
      } catch (e) {
        // Message.error("上传失败请重试");
        return e;
      }
    },
    randomString(num) {
      const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let res = "";
      for (let i = 0; i < num; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
      }
      return res;
    },
    async getOssData() {
      const [err, res] = await this.$http.post("web/User/getSts", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        return;
      }
      this.dataObj = res;
    },
    handfileErr(err, file, fileList) {
      this.form.id_card_front = "";
    },
    handfileErrBack(err, file, fileList) {
      this.form.id_card_back = "";
    },
    async submitForm() {
      this.$refs.form.validate(async (v, i) => {
        if (v) {
          const [err, res] = await this.$http.post("web/user/idCard", {
            id: this.$store.state.user_id,
            token: this.$store.state.login_token,
            ...this.form,
          });
          if (err) {
            return;
          }
          this.$router.push("/");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  justify-content: center;
  /deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 400px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 400px;
    height: 178px;
    display: block;
  }
}
</style>